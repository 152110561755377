import { mapClient } from './mapClient'
import config from '../config'

const genRedirectURI = (assistantId: string, clientId: string) => {
  const origin = window.location.origin
  const queryParams = new URLSearchParams({
    assistantId,
    clientId,
    grant_type: process.env.REACT_APP_MOODLE_GRANT_TYPE!,
    scope: process.env.REACT_APP_MOODLE_SCOPE!
  })
  return `${origin}/?${queryParams}`
}

export const hasQueryCode = () => {
  const query = new URLSearchParams(window.location.search)
  const code: string | null = query.get('code')

  if (!!code) {
    return
  }
  moodleLoginRedirect()
}

export const moodleLoginRedirect = () => {
  try {
    const query = new URLSearchParams(window.location.search)
    const assistantId = query.get('assistantId') || config.defaultAssistantId
    const { baseUrl, clientId } = mapClient().find(
      ({ clientId }) =>
        clientId === (query.get('clientId') || config.defaultClientId)
    )!

    const queryParams = new URLSearchParams({
      client_id: clientId,
      response_type: process.env.REACT_APP_MOODLE_RESPONSE_TYPE!,
      redirect_uri: genRedirectURI(assistantId, clientId),
      scope: process.env.REACT_APP_MOODLE_SCOPE!
    })

    window.location.href = `${baseUrl}/local/oauth/login.php?${queryParams}`
  } catch (error) {
    console.error(error)
    throw new Error('Incorrect ClientId')
  }
}
