import * as R from 'ramda'
import { ClientConfig } from '../schema/client'

export const mapClient = (): ClientConfig[] => {
  const clientIds: string[] = JSON.parse(
    process.env.REACT_APP_MOODLE_CLIENT_IDS!
  )
  const clientUrls: string[] = JSON.parse(
    process.env.REACT_APP_MOODLE_BASE_URLS!
  )
  return R.zipWith(
    (clientId, baseUrl) => ({ clientId, baseUrl }),
    clientIds,
    clientUrls
  )
}
