import { DigitalHuman, PasscodeOverlay } from 'uneeq-react-ui'
import React, { useState, useRef } from 'react'
import { Box } from 'rebass'

import { ThemeProvider } from 'emotion-theming'

import theme from '../theme'
import config from '../config'
import Home from './Home'
import assets from './assets'

import { testState } from 'uneeq-react-core'
import SessionTimedOut from './SessionTimedOut'
import { moodleLoginRedirect } from '../utils/moodle'

const backgroundStyle = {
  label: 'wrapper',
  width: '100%',
  minHeight: '100%',
  backgroundSize: 'cover',
  backgroundPosition: 'right bottom',
  backgroundColor: '#000000',
  backgroundImage: [
    'none',
    'none',
    'none',
    'none',
    `url('/images/idle.jpg')`,
    `url('/images/idle.jpg')`
  ],
  overflow: 'hidden',
  position: 'absolute'
}

const loadingTips = [
  {
    title: 'Spinning up the propeller',
    showOnDesktop: true,
    showOnMobile: true
  },
  {
    title: 'Applying maximum thrust',
    showOnDesktop: true,
    showOnMobile: true
  },
  {
    title: 'Increasing the altitude',
    showOnDesktop: true,
    showOnMobile: true
  },
  {
    title: 'Retracting the landing gear',
    showOnDesktop: true,
    showOnMobile: true
  }
]

const App = () => {
  // For faster testing, skip straight to digitalHuman
  const [step, goTo] = useState(testState ? 'digitalHuman' : 'welcome')

  // passcode is an overlay, not a step
  const [showPasscode, setShowPasscode] = useState(false)

  const tokenRef = useRef<string>()
  const start = () => {
    // Show passcode overlay or go straight to digitalHuman
    if (config.usePasscode) {
      setShowPasscode(true)
    } else {
      goTo('digitalHuman')
    }
  }

  const startWithToken = (token: string) => {
    tokenRef.current = token
    goTo('digitalHuman')
    setShowPasscode(false)
  }

  const restart = () => goTo('welcome')
  const reload = moodleLoginRedirect

  return (
    <ThemeProvider theme={theme}>
      <Box
        sx={{
          ...backgroundStyle,
          overflow: step === 'welcome' ? 'visible' : 'hidden'
        }}
      >
        {step === 'digitalHuman' ? (
          <DigitalHuman
            assets={assets}
            config={config}
            token={tokenRef.current}
            loadingTips={loadingTips}
            onTimedOut={() => goTo('timed-out')}
            restart={restart}
            reload={reload}
            onSessionEnded={() => goTo('restart')}
          />
        ) : step === 'timed-out' ? (
          <SessionTimedOut restart={restart} />
        ) : (
          <Home startSession={start} />
        )}
        {showPasscode && (
          <PasscodeOverlay
            start={startWithToken}
            close={() => setShowPasscode(false)}
            config={config}
          />
        )}
      </Box>
    </ThemeProvider>
  )
}

export default App
