import React from 'react'
import { Flex, Text, Button } from 'rebass'
import { ReactComponent as ExclamationIcon } from '../../assets/img/exclamation.svg'
import { styles as s } from './styles'
const styles = s.error

interface ErrorProps {
  message?: string
  clearError: () => void
}
const Error: React.FC<ErrorProps> = ({
  message = `An unknown error ocurred`,
  clearError
}) => (
  <Flex sx={styles.container}>
    <Flex sx={styles.sideBorderError} />
    <Flex sx={styles.warning}>
      <Flex sx={styles.headingContainer} justifyContent="space-between">
        <Flex>
          <Flex sx={styles.icon}>
            <ExclamationIcon />
          </Flex>
          <Text sx={styles.heading}>Error</Text>
        </Flex>
        <Flex>
          <Button variant="outline" color="error" onClick={() => clearError()}>
            Close
          </Button>
        </Flex>
      </Flex>
      <Text sx={styles.message}>{message}</Text>
    </Flex>
  </Flex>
)

export default Error
