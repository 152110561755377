import { useWindowWidth } from '@react-hook/window-size'
import React, { useState } from 'react'
import { Box, Button, Flex, Text } from 'rebass'
import { trackHandler, useSupportedBrowser } from 'uneeq-react-core'
import { UnsupportedBrowser } from 'uneeq-react-ui'
import config from '../../config'
import styles from './styles'

const Paragraphs = () => (
  <>
    <Text as="p">
      We take your privacy seriously, we only use video and audio data so that
      Amelia can understand what you are saying to it. We then share audio data
      with third party service partners who help us with transcription. Neither
      they or we store any audio data once we have completed the transcription.
      For more information read our{' '}
      <Text
        as="a"
        href="https://cbtaaero.com/index.php?option=com_content&view=article&id=23&catid=2"
        target="_blank"
        rel="noreferrer noopener"
        sx={styles.privacyPolicyLink}
      >
        privacy policy
      </Text>
      .
    </Text>
  </>
)

const SophieVideo = () => {
  const width = useWindowWidth()
  const smallScreen = width < 1024

  return (
    <video
      playsInline
      autoPlay
      muted
      loop
      poster={smallScreen ? '/images/idle-mobile.jpg' : '/images/idle.jpg'}
    >
      <source
        src={smallScreen ? '/videos/idle-mobile.webm' : '/videos/idle.webm'}
        type="video/webm"
      />
      <source
        src={smallScreen ? '/videos/idle-mobile.mp4' : '/videos/idle.mp4'}
        type="video/mp4"
      />
    </video>
  )
}

interface HomeProps {
  startSession: (token: string) => void
}

const Home: React.FC<HomeProps> = ({ startSession }) => {
  const { isBrowserSupported } = useSupportedBrowser()
  const [showPasscode, setShowPasscode] = useState(false)

  if (showPasscode && !isBrowserSupported) {
    return <UnsupportedBrowser close={() => setShowPasscode(false)} />
  }

  const StartButton = ({ sx }: any) => (
    <Button
      variant="outline"
      onClick={() => {
        trackHandler(startSession, 'lets-chat-btn')(true)
      }}
      sx={{ ...styles.letsChatButton, ...sx }}
    >
      Let's chat
    </Button>
  )

  return (
    <Flex sx={styles.mainContainer}>
      <Text sx={styles.mobileImSophie}>
        Meet {config?.avatarName}, our new digital tutor
      </Text>
      <SophieVideo />

      <Box sx={styles.textContainer}>
        <Box sx={styles.titleContainer}>
          <Text sx={styles.imSophieText}>Meet {config?.avatarName}</Text>
        </Box>
        <Box sx={styles.videoContainer}>
          <video
            autoPlay={false}
            loop={true}
            playsInline={true}
            controls={true}
            style={{ width: '335px', objectFit: 'cover' }}
          >
            <source src="/videos/intro.webm" type="video/mp4" />
            <source src="/videos/intro.mp4" type="video/mp4" />
            Loading...
          </video>
        </Box>
        <Text sx={styles.subtitle}>
          I’m a digital tutor that can help you learn.
        </Text>
        <StartButton sx={styles.startButton} />

        <Flex
          sx={{
            ...styles.paragraphContainer
          }}
        >
          <Paragraphs />
        </Flex>
        <StartButton
          sx={{ display: ['none', 'none', 'none', 'none', 'block', 'block'] }}
        />
      </Box>
    </Flex>
  )
}

export default Home
